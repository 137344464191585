import revive_payload_client_l2pkl9ElCy from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@types+node@22.10.2_eslint@9.17.0_rollup@2.79.2_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_L1WCiUnxvg from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_rollup@2.79.2_typescript@5.7.2_vite@6.0.6_vue@3.5.13/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_7u0e9MP2Bg from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@types+node@22.10.2_eslint@9.17.0_rollup@2.79.2_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_l1UYzWj90R from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@types+node@22.10.2_eslint@9.17.0_rollup@2.79.2_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_Nx2uzTY1J7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@types+node@22.10.2_eslint@9.17.0_rollup@2.79.2_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HMekT5ntBC from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@types+node@22.10.2_eslint@9.17.0_rollup@2.79.2_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_n0tCWKYjg8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@types+node@22.10.2_eslint@9.17.0_rollup@2.79.2_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fHJS2pKXjJ from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@2.79.2_typescript@5.7.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_vCHZeknQeA from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@types+node@22.10.2_eslint@9.17.0_rollup@2.79.2_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_qNE5Q4fhJy from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_rollup@2.79.2_vite@6.0.6_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_wy0B721ODc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_BgDihNaN7u from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@2.79.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_48JOMIbEOi from "/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.7_next-auth@4.21.1_rollup@2.79.2_typescript@5.7.2_vue@3.5.13/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_DIxJ7xZIPW from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@2.79.2/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_4AFCjljswc from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_pinia@2.3.0_rollup@2.79.2/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import vuetify_no_client_hints_kU7caAPv8q from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_rollup@2.79.2_typescript@5.7.2_vite@6.0.6_vue@3.5.13/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import hotjar_pBWYs5JQbk from "/vercel/path0/plugins/hotjar.ts";
import octokit_client_D2Nkfi13sb from "/vercel/path0/plugins/octokit.client.ts";
import vue_datepicker_dyozMmv23j from "/vercel/path0/plugins/vue-datepicker.ts";
import vue_draggable_OKRqLUiGtd from "/vercel/path0/plugins/vue-draggable.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/vercel/path0/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_l2pkl9ElCy,
  vuetify_icons_L1WCiUnxvg,
  unhead_7u0e9MP2Bg,
  router_l1UYzWj90R,
  navigation_repaint_client_Nx2uzTY1J7,
  check_outdated_build_client_HMekT5ntBC,
  chunk_reload_client_n0tCWKYjg8,
  plugin_vue3_fHJS2pKXjJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vCHZeknQeA,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_qNE5Q4fhJy,
  plugin_wy0B721ODc,
  plugin_client_BgDihNaN7u,
  plugin_48JOMIbEOi,
  plugin_DIxJ7xZIPW,
  plugin_4AFCjljswc,
  vuetify_no_client_hints_kU7caAPv8q,
  hotjar_pBWYs5JQbk,
  octokit_client_D2Nkfi13sb,
  vue_datepicker_dyozMmv23j,
  vue_draggable_OKRqLUiGtd,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]