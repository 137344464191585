
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_939rArDAEosqMeta } from "/vercel/path0/pages/list/[id].vue?macro=true";
import { default as listsIm5cAseXMXMeta } from "/vercel/path0/pages/lists.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as _91id_93YjTnfCyYZ0Meta } from "/vercel/path0/pages/todo/[id].vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountLUTTRGMhg3Meta || {},
    component: () => import("/vercel/path0/pages/account.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "list-id",
    path: "/list/:id()",
    component: () => import("/vercel/path0/pages/list/[id].vue")
  },
  {
    name: "lists",
    path: "/lists",
    meta: listsIm5cAseXMXMeta || {},
    component: () => import("/vercel/path0/pages/lists.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchBIFC2dE21WMeta || {},
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    component: () => import("/vercel/path0/pages/settings.vue")
  },
  {
    name: "todo-id",
    path: "/todo/:id()",
    component: () => import("/vercel/path0/pages/todo/[id].vue")
  }
]